<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> {{title}}</span>
                <span class="head__sub-title">&nbsp;/&nbsp;{{subtitle}}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <nav class="section__navigation plr__12 pt__12">
                <SegmentControl
                    :segment-control-class="'mb__12'"
                    :field_id="'component'"
                    :items="menuSettings"
                    :items-errors="componentsErrors"
                    v-model="component"
                    v-if="menuSettings.length > 1"
                />
            </nav>
            <keep-alive>
            <component :is="component"
                    v-model="unit"
                    :unitId="unitId"
                    :errors="errors"
            /><!-- unitId!!! fix bug -->
            </keep-alive>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{action}} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import { required, integer, helpers } from 'vuelidate/lib/validators'
    import {diffObjectsRecursiveExceptions} from "@/lib/lib";
    const phone = helpers.regex('phone', /^([+]?[0-9\s-()]{3,25})*$/)

    export default {
        name: "UnitEdit",
        props: {
            'unitId': [String,Number],
            'time': [String,Number],
            'options': {
                type: Object,
                default: () => ({
                    components: ['UnitEdit_Options', 'UnitEdit_Icon', 'UnitEdit_AddInfo', 'UnitEdit_Sensors', 'UnitEdit_License',]
                })
            },
        },
        components: {
            'UnitEdit_Options': () => import('./UnitEdit_Options.lite.vue'),
            'UnitEdit_AddInfo': () => import('./UnitEdit_AddInfo.lite.vue'),
            'UnitEdit_Sensors': () => import('./UnitEdit_Sensors.lite.vue'),
            'UnitEdit_License': () => import('./UnitEdit_License.lite.vue'),
            'UnitEdit_Icon': () => import('./UnitEdit_Icon.lite.vue'),
        },
        data() {
            return {
                components: [
                    {
                        name: this.$i18n.t('UnitEdit.components.title.Settings'),
                        subtitle: this.$i18n.t('UnitEdit.components.descr.Settings'),
                        component: 'UnitEdit_Options',
                    },
                    {
                        name: this.$i18n.t('UnitEdit.components.title.Icon'),
                        subtitle: this.$i18n.t('UnitEdit.components.descr.Icon'),
                        component: 'UnitEdit_Icon',
                    },
                    {
                        name: this.$i18n.t('UnitEdit.components.title.Info'),
                        subtitle: this.$i18n.t('UnitEdit.components.descr.Information'),
                        component: 'UnitEdit_AddInfo',
                    },
                    {
                        name: this.$i18n.t('UnitEdit.components.title.In/Out'),
                        subtitle: this.$i18n.t('UnitEdit.components.descr.Inputs & Outputs'),
                        component: 'UnitEdit_Sensors',
                    },
                    {
                        name: this.$i18n.t('UnitEdit.components.title.Licenses'),
                        subtitle: this.$i18n.t('UnitEdit.components.descr.Licenses'),
                        component: 'UnitEdit_License',
                    },
                ],
                component: null,
                componentsErrors: [],
                errors: {},
                unit: {},
                org: null,
                oldId: null,
                oldTime: null,
            }
        },
        validations: {
            unit: {
                //UnitEdit_Sensors
                // unit_inputs: {},
                // unit_outputs: {},

                //UnitEdit_Options
                // creator: { required },
                name: { required },
                hw_id: { required },
                hw_type: { required },
                hwType: {
                    hw_type: { required },
                    hw_title: { required }
                },
                phone: { phone },
                // icon: {},
                // icon_color: {},
                // platenumber: {},
                // mark: {},
                // model: {},
                // year: { integer, between: {1900, 2050} },
                // vin: {},
                max_speed: { integer },

                //UnitEdit_License
                addLicenseKey: { required },

                //UnitEdit_Sensors
                //UnitEdit_Options
            }
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
                "isDealer",
                "getAppUser",
                "unitsByIds",
            ]),
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId] || {}
            },
            menuSettings(){
                let components = this.components
                if(this.options && this.options.components){
                    components = components.filter(c => {
                        return this.options.components.includes(c.component)
                    })
                }
                if(!this.isDealer){
                    components = components.filter( el => !el.component.includes('UnitEdit_License'))
                }
                return components
            },
            isNew(){
                return !(this.unitId && this.unitId>0)
            },
            _unit(){
                return this.unitsByIds[this.unitId]
            },
            title(){
                return (this.unitId>0) ? this.unit.name : this.$t('UnitEdit.title.Create unit')
            },
            subtitle(){
                let selected = this.components.find(c => { return c.component === this.component})
                return selected ? selected.subtitle : ''
            },
            action(){
                return (this.unitId>0) ? this.$t('buttons.Save') : this.$t('buttons.Create')
            },
        },
        methods: {
            ...mapMutations([
               "showMainList",
            ]),
            ...mapActions([
                "fetchUnitFullInfo",
                "fetchUnitsIcons",
                "updateUnitCounters",
                "saveUnitsHwType",
                "setPopup",
                "saveUnit",
                "saveProduct",
            ]),
            onCancel(){
                this.showMainList()
                this.$emit('close-dialog')
            },
            validateUnit(){
                let components = this.components.map(c => c.component)
                this.$v.$touch()
                this.errors = {}
                this.componentsErrors = [];
                let hasError;
                if (this.$v.$invalid) {
                    if(components.includes('UnitEdit_Sensors')){
                        hasError = false
                        // //unit_inputs
                        // if(!this.$v.unit.unit_inputs.required) {
                        //     this.errors.unit_inputs= ' is required'
                        // }else
                        // if(!this.$v.unit.unit_inputs.xxx){
                        //     this.errors.unit_inputs= ' '
                        // }
                        // //unit_outputs
                        // if(!this.$v.unit.unit_outputs.required) {
                        //     this.errors.unit_outputs= ' is required'
                        // }else
                        // if(!this.$v.unit.unit_outputs.xxx){
                        //     this.errors.unit_outputs= ' '
                        // }
                        if(hasError){
                            this.componentsErrors.push('UnitEdit_Sensors')
                        }
                    }
                    if(components.includes('UnitEdit_Options')) {
                        hasError = false
                        //creator
                        // if(!this.$v.unit.creator.required) {
                        //     this.errors.creator= 'Creator is required'
                        // }
                        //name
                        if(!this.$v.unit.name.required) {
                            hasError =
                            this.errors.name= 'Name is required'
                        }
                        //hw_id
                        if(!this.$v.unit.hw_id.required) {
                            hasError =
                            this.errors.hw_id= 'IMEI is required'
                        }
                        //hw_type
                        if(!this.unit.hwType){
                            //hw_type
                            if(!this.$v.unit.hw_type.required) {
                                hasError =
                                this.errors.hw_type= 'Hardware is required'
                            }
                        } else {
                            //hwType
                            if(!this.$v.unit.hwType.hw_type.required) {
                                hasError =
                                this.errors.hw_type= 'Hardware is required'
                            }
                            if(!this.$v.unit.hwType.hw_title.required){
                                hasError =
                                this.errors.hw_title= 'Title is required'
                            }
                        }
                        //phone
                        if(!this.$v.unit.phone.phone){
                            hasError =
                            this.errors.phone= 'Enter valid phone number'
                        }
                        if(hasError){
                            this.componentsErrors.push('UnitEdit_Options')
                        }
                    }
                    if(components.includes('UnitEdit_AddInfo')) {
                        hasError = false
                        //max_speed
                        if(!this.$v.unit.max_speed.integer){
                            hasError =
                            this.errors.max_speed= 'Max permissible speed must be integer'
                        }
                        if(hasError){
                            this.componentsErrors.push('UnitEdit_AddInfo')
                        }
                    }
                    if(components.includes('UnitEdit_Icon')) {
                        hasError = false;
                        //icon
                        if(hasError){
                            this.componentsErrors.push('UnitEdit_AddInfo')
                        }
                    }
                    if(components.includes('UnitEdit_License')) {
                        hasError = false;
                        //License key
                        if(!this.$v.unit.addLicenseKey.required && this.isNew) {
                            hasError =
                            this.errors.addLicenseKey= 'License Key is required'
                        }
                        if(hasError){
                            this.componentsErrors.push('UnitEdit_License')
                        }
                    }
                }
            },
            onSave(){
                this.validateUnit()
                if(Object.keys(this.errors).length)
                    return false // stop here if form is invalid

                //ToDo fix
                let title = `${this.action} unit ${this.unit.name}`
                this.setPopup({
                    title,
                    methods: {
                        ok: () => this.onSave_(),
                        //cancel: () => {},
                    }

                })
            },
            onSave_(){
                if (this.unit.hwType) {
                    this.onSaveUnitsHwType()
                } else {
                    this.onSaveUnit()
                }
            },
            onSaveUnitsHwType(){
                let hwType = { ...this.unit.hwType }
                hwType.hw_title = hwType.hw_title.toLowerCase()
                this.saveUnitsHwType(hwType)
                    .then((/*res*/) => {
                        this.unit.hw_type = hwType.hw_type
                        delete this.unit.hwType
                        this.onSaveUnit()
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            // console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
            onSaveUnit(){
                let unit = this.unit
                if(unit.id) {
                    let org = JSON.parse(this.org)
                    let diffUnit = diffObjectsRecursiveExceptions(org, unit)
                    unit = { id: unit.id, ...diffUnit}
                }
                // console.log(unit)
                this.saveUnit(unit)
                    .then((response) => {
                        // console.log(response)
                        if(unit.mileage > '') {
                            unit.id = response.data.id
                            //ToDo fix wait result and errors
                            this.updateUnitCounters({id: unit.id, counters: {mileage: unit.mileage}})
                                .then((response) => {
                                    if(response.data?.error){
                                        console.warn(response.data);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                        }
                        this.errors = {}
                        this.$toast.success(this.$t("success!"))
                        this.showMainList()
                        this.$emit('close-dialog')
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            // console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
            updateUnitEdit(){
                //console.log('updateUnitEdit', this.unitId)
                this.oldTime = this.time
                this.oldId = this.unitId
                let unit = {}
                if(this.unitId) {
                    //this.fetchUnitFullInfo(this.unitId)
                    unit = { ...this.unitsByIds[this.unitId] }
                }
                //fix()
                {
                    let size = 2

                    if(!unit.inputs) unit.inputs = []
                    while(unit.inputs.length<size){
                        unit.inputs.push({name:'',param:'',notif:false})
                    }
                    if(!unit.outputs) unit.outputs = []
                    while(unit.outputs.length<size){
                        unit.outputs.push({name:'',param:''})
                    }
                    if(!unit.ignition) {
                        unit.ignition = {type:'',voltage:'',param:''}
                    }
                }
                if(!unit.hwType) unit.hwType = null
                this.errors = {}
                this.componentsErrors = [];
                this.unit = {...unit, mileage: (this.unitLmsg?.counters?.mileage || 0)}
                this.org = JSON.stringify(this.unit)
            },
        },
        created() {
            //console.log('UnitEdit created', this.unitId)
            //this.component = this.components[0]['component']
            if(this.menuSettings.length) {
                this.component = this.menuSettings[0].component
            }
        },
        updated() {
            //console.log('UnitEdit updated', this.unitId)
            //if(this.oldId !== this.unitId)
            if(this.oldTime !== this.time)
            {
                //console.log('!=', this.oldId, this.unitId)
                this.updateUnitEdit()
            }
        },
        mounted() {
            //console.log('UnitEdit mounted', this.unitId)
            this.updateUnitEdit()
        }
    }
</script>

<style scoped>

</style>